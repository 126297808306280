import React, { FC, useRef, useState, useEffect, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { PuxFooter, PuxModal, PuxModalContent, PuxSheet, PuxText, useBreakpoint } from "@phonero/pux-react"

import {
  useCurrentSubscriptionId,
  UrlHashes,
  useGoBack,
  ProductList,
  CodewordType,
  ExtraDataPackage,
  InlineError,
  useAppTranslations,
  useAppQuery,
  useHasSpeedBoost,
  useAnalytics,
  Product,
} from "@phonero/common-ux"
import { Checkout } from "./components/Checkout"
import DialogTitle from "../components/layout/DialogTitle"
import { SubscriptionExtraDataPackagesDocument } from "./ExtraDataQueries.generated"

const analyticsProps = {
  orderKind: "add-data-boost",
}

export const AddDataBoostDialog: FC = () => {
  const firstModalRef = useRef<HTMLPuxModalElement>(null)

  const { t } = useAppTranslations()
  const { logOrderProcess, logOrderProcessCancellation, session } = useAnalytics()
  const { hash } = useLocation()
  const isOpen = hash === UrlHashes.boost
  // We want a new session-id every time a user closes the dialog
  useEffect(() => {
    const sId = session.getSessionId()
    if (isOpen && !sId) {
      session.newSessionId()
      logOrderProcess({
        ...analyticsProps,
        stepName: "view",
      })
      return
    }
    if (!isOpen && sId) {
      session.resetSessionId()
      return
    }
  }, [logOrderProcess, isOpen, session])
  const [selected, _setSelected] = useState<null | undefined | Product>()
  const lastOrderStepName = useRef("deselect")
  const __setSelectedProduct: typeof _setSelected = (pf) => {
    _setSelected((state) => {
      const next = typeof pf === "function" ? pf(state) : pf

      if (isOpen) {
        if (next) {
          if (lastOrderStepName.current !== "select") {
            lastOrderStepName.current = "select"
            logOrderProcess({
              ...analyticsProps,
              stepName: "select",
              productID: next.id as any, // TODO: fix any hack
              productName: next.name,
            })
          }
        } else {
          if (lastOrderStepName.current !== "deselect") {
            lastOrderStepName.current = "deselect"
            logOrderProcess({ ...analyticsProps, stepName: "deselect" })
          }
        }
      }
      return next
    })
  }
  const setSelected = useCallback(__setSelectedProduct, [logOrderProcess, _setSelected, isOpen])
  const { subscriptionId } = useCurrentSubscriptionId()
  const { goBack } = useGoBack({
    onlyIfRouteMatch: "boost",
    didGoBack: () => setSelected(null),
  })
  const { error, data, loading } = useAppQuery(SubscriptionExtraDataPackagesDocument, {
    onCompleted: (d) => {
      if (!d.mobileSubscription?.products) {
        return
      }
      logOrderProcess({
        ...analyticsProps,
        stepName: "got-products",
        attributes: {
          productCount: String(d.mobileSubscription.products.length),
        },
      })
    },
    skip: !subscriptionId,
    variables: { subscriptionId },
    fetchPolicy: "network-only",
  })

  const hasSpeedBoost = useHasSpeedBoost()

  const boostType = !loading && data && hasSpeedBoost ? CodewordType.SpeedBoost : CodewordType.ExtraBoost

  let { products } = data?.mobileSubscription || {}

  const onClose = () => {
    lastOrderStepName.current = "deselect"
    goBack()
    logOrderProcessCancellation()
    setSelected(null)
  }
  const xs = useBreakpoint("xs", { useMax: true, useMin: false })
  return (
    <PuxModal keyboardClose ref={firstModalRef} swipeToClose isOpen={isOpen} onWillDismiss={onClose}>
      <DialogTitle
        onlyIfRouteMatch="boost"
        {...(selected && {
          onBackClick: () => setSelected(null),
        })}
      >
        {t("ExtraData.Header", { context: "" + boostType })}
      </DialogTitle>

      {error ? (
        <PuxModalContent>
          <InlineError code="getExtraPackages" error={error} />
        </PuxModalContent>
      ) : selected ? (
        <Checkout
          product={selected as any}
          onClose={onClose}
          logOrderProcess={(params) =>
            logOrderProcess({
              ...analyticsProps,
              stepName: "confirm-purchase",
              ...params,
            })
          }
          onCancel={() => setSelected(undefined)}
        />
      ) : (
        <PuxModalContent>
          <PuxSheet style={{ textAlign: "center", paddingBottom: 0 }} color="light">
            <div style={{ padding: "1rem 2rem" }}>
              <PuxText>{t("ExtraData.Notice", { context: "" + boostType })}</PuxText>
            </div>
          </PuxSheet>
          <PuxSheet style={{ ...(xs && { "--padding": 0 }) }} color="light">
            <ProductList
              emptyTitle={t("ExtraData.NoProductsAvailableTitle")}
              emptyMsg={
                <>
                  <p>
                    <PuxText>{t("ExtraData.NoProductsAvailable")}</PuxText>
                  </p>

                  <p>
                    <PuxText>{t("ExtraData.NoProductsAvailableInfo")}</PuxText>
                  </p>
                </>
              }
              products={
                products?.filter(
                  (product: any /*TODO: Fix any hack from orginially ExtraDataPackageOfferingProduct */) =>
                    (!hasSpeedBoost && product.type === CodewordType.ExtraBoost) ||
                    product.type === CodewordType.SpeedBoost
                ) ?? []
              }
              placeholders={3}
              Component={ExtraDataPackage}
              onSelectProduct={setSelected}
              animated={loading}
            />
          </PuxSheet>
        </PuxModalContent>
      )}
      <PuxFooter />
    </PuxModal>
  )
}
