import css from "./InfoBlock.module.scss"
import { PuxIcon, PuxTypography } from "@phonero/pux-react"
import { info } from "@phonero/pux-icons/icons"
import { approved } from "@phonero/pux-icons/icons"
import { FC } from "react"
import classNames from "classnames"
import { PredefinedColors } from "@phonero/pux-core"

export const InfoBlock: FC<
  Partial<Pick<JSX.IntrinsicElements["div"], "style" | "children" | "className">> & {
    color?: "light-green" | "light-yellow" | PredefinedColors | undefined
    textColor?: "light-green" | "light-yellow" | PredefinedColors | undefined
    background?: "light-green" | "light-yellow" | PredefinedColors | undefined
    iconCustom?: any
    iconColor?: string
    outline?: boolean
    noPadding?: boolean
  }
> = ({ children, style, className, color, textColor, background, outline, noPadding, iconCustom }) => {
  return (
    <div
      className={classNames(css.panel, className)}
      style={{
        ...(!!outline && {
          outline: color !== undefined ? `2px solid var(--pux-color-${color})` : "2px solid var(--pux-color-dark)",
        }),
        ...(!!background && {
          background:
            background === "light-yellow"
              ? "rgba(255, 218, 28, 0.07)"
              : background === "light-green"
              ? "rgba(244, 251, 247)"
              : `var(--pux-color-${background})`,
          color: `var(--pux-color-${background}-contrast)`,
        }),
        padding: !!noPadding ? 0 : "20px",
        ...style,
      }}
    >
      <PuxIcon
        icon={iconCustom ? iconCustom : info}
        style={{ marginRight: 15, verticalAlign: "middle", width: "50px" }}
        slot="start"
        {...(color === "light-yellow"
          ? {
              className: "icon-yellow",
            }
          : { color })}
      />
      <PuxTypography
        variant={"info-small"}
        style={{
          marginBlock: "auto",
          paddingTop: "1px",
          color: `var(--pux-color-${textColor})`,
        }}
        component={"div"}
      >
        {children}
      </PuxTypography>
    </div>
  )
}
